<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <v-card-actions>
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  depressed
                  tile
                  @click="add_user()"
                  color="primary"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        
        <v-form
          ref="form" 
          v-model="valid"
          lazy-validation
        >
          <v-card>
            <v-card-title>Dane użytkownika</v-card-title>
            <v-card-actions>
              <v-switch
                v-model="client_pl"
                @click="changeCountry()"
                inset
                :label="'Klient: ' + (!client_pl?'Zagraniczny':'Polski')"
                class="classic_switch"
              ></v-switch>
              <v-switch
                v-model="gus_client"
                :disabled="!client_pl"
                inset
                label="Pobierz dane z GUS"
                class="classic_switch"
              ></v-switch>
              <v-switch
                v-model="set_password"
                inset
                label="Wprowadź ręcznie hasło"
                class="classic_switch"
              ></v-switch>
            </v-card-actions>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6" lg="4">

                  <v-text-field
                    v-model="user_data.name"
                    label="Nazwa"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                </v-col>
                <v-col cols="12" md="6" lg="4">

                  <v-text-field
                    v-model="user_data.email"
                    label="Email"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                </v-col>
                <v-col cols="12" lg="4">

                  <!-- <v-select
                    v-model="role_id"
                    :items="role"
                    item-text="name"
                    item-value="id"
                    label="Rola"
                    single-line
                    :rules="$store.state.rules.not_null"
                  ></v-select> -->

                  <v-autocomplete
                    v-model="role_id"
                    :items="role"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Rola"
                    :rules="$store.state.rules.not_null"
                  ></v-autocomplete>

                </v-col>

                <v-col cols="12" lg="6">

                  <v-card
                    outlined
                    v-if="set_password"
                  >
                    <v-card-title>Hasło do konta</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="user_data.pass"
                            label="Hasło"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="user_data.pass_repeat"
                            label="Powtórz hasło"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>

                  </v-card>

                </v-col>
                <v-col cols="12" :lg="set_password?'6':'12'">

                  <v-card
                    outlined
                  >
                    <v-card-title>Dane firmy</v-card-title>
                    <v-card-text 
                      v-if="!gus_client"
                    >
                      <v-text-field
                        v-model="user_data.nip"
                        label="NIP"
                      ></v-text-field>

                      <v-text-field
                        v-model="user_data.full_name"
                        label="Pełna nazwa firmy"
                      ></v-text-field>

                      <v-text-field
                        v-model="user_data.city"
                        label="Miasto"
                      ></v-text-field>

                      <v-text-field
                        v-model="user_data.zip_code"
                        label="Kod pocztowy"
                      ></v-text-field>

                      <v-text-field
                        v-model="user_data.address"
                        label="Adres"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-text
                      v-if="gus_client"
                    >
                      <v-text-field
                        v-model="user_data.nip"
                        label="NIP"
                      ></v-text-field>
                    </v-card-text>

                  </v-card>

                </v-col>

                <v-col cols="12" lg="4">

                  <v-text-field
                    v-model="user_data.recommending_user"
                    label="Osoba polecająca (KOD)"
                  ></v-text-field>
                
                </v-col>
              </v-row>

              
              <Contacts
                :contacts_person="contacts_person"
                @update_me="updateContacts"
              />

            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import Contacts from '@/components/Customers/Contacts.vue';

export default {
  components: {
    Contacts,
  },
  data: () => ({
    id: 0,
    valid: false,
    show1: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista Klientów',
        disabled: false,
        to: '/klienci',
      },
      {
        text: 'Dodawanie Klienta',
        disabled: true,
        to: '/edit',
      }
    ],

    client_pl: true,
    gus_client: false,
    set_password: false,

    role_id: 0,
    role: [
      {
        id: 0,
        name: ''
      },
    ],

    contacts_person: [{
      name: '',
      tel: '',
      email: '',
      position: ''
    }],

    user_data: {
      email: '',
      role_id: 0,
      name: '',
      nip: '',
      recommending_user: '',
    },
  }),
  methods: {
    changeCountry(){
      if(!this.client_pl) this.gus_client = false;
    },
    updateContacts(v){
      this.contacts_person = v;
    },
    add_user() {
      
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("name", this.user_data.name);
        formData.append("email", this.user_data.email);
        formData.append("role_id", this.role_id);
        if(this.user_data.recommending_user.length) formData.append("recommending_user", this.user_data.recommending_user);
        formData.append("client", 1);
        if(this.user_data.nip) formData.append("nip", this.user_data.nip);
        // Jezeli wpisalismy nazwe osoby kontaktowej dodaj osobe
        if(this.contacts_person[0].name.length) {
          for(let item in this.contacts_person){
            formData.append("contact["+item+"][name]", this.contacts_person[item].name);
            formData.append("contact["+item+"][tel]", this.contacts_person[item].tel);
            formData.append("contact["+item+"][email]", this.contacts_person[item].email);
            formData.append("contact["+item+"][position]", this.contacts_person[item].position);
          }
        }

        // Jeżeli nie wysyłamy maila (hasło ustawiamy sami)
        if(this.set_password) {
          formData.append("password", this.user_data.pass);
          formData.append("password_confirmation", this.user_data.pass_repeat);
        }
        // Jeżeli wysyłamy maila
        else {
          formData.append("mail", 1);
        }

        // Jeżeli Klient leci po GUS
        if(this.gus_client) {
          formData.append("gus", 1);
        }
        // Jeżeli Klienta dodajemy bez GUS
        else {
          if(this.user_data.full_name) formData.append("full_name", this.user_data.full_name);
          if(this.user_data.city) formData.append("city", this.user_data.city);
          if(this.user_data.address) formData.append("address", this.user_data.address);
          if(this.user_data.zip_code) formData.append("zip_code", this.user_data.zip_code);
        }


        this.$axios({url: this.$store.state.api +'/auth/register', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            this.$router.push('/klienci');
            this.$store.commit("snackbar", {
              text: "Dodano Klienta",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
  },
  mounted(){
    this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/auth/role', data: {}, method: 'GET' })
        .then(resp => {
          this.role = resp.data;
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
  }
};
</script>

<style lang="scss" scoped>
.classic_switch {
  margin-left: 15px;
}
.checkbox {
  margin-top: 0;
}
</style>